import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { Route, Routes } from "react-router-dom";
import { AdminPage } from "./pages/admin-page";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { ProtectedPage } from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";

export const App: React.FC = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // Polling for session every 60 seconds
    const interval = setInterval(async () => {
      try {
        await getAccessTokenSilently({
            cacheMode: 'off',
        });
      } catch {
          console.log('user has no session, need to log out');
      }
    }, 60000);

    // Clearing the interval
    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
    <Routes>
      <Route 
        path="/" 
        element={<AuthenticationGuard component={HomePage} />} 
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      />
      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminPage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
